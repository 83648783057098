
export default {
  name: "BodyConditionScoreSelector",
  model: {
    prop: "modelValue",
    event: "input"
  },
  props: {
    modelValue: {
      type: String,
      require: true
    },
    tooThin: {
      type: Array,
      require: true
    },
    ideal: {
      type: Array,
      require: true
    },
    tooHeavy: {
      type: Array,
      require: true
    }
  }
};
