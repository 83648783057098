
import Vue from "vue";
import {
  mapBreedsPayloadToViewModel,
  mapPetBodyConditionsPayloadToViewModel,
  mapToPartialUpdatePetRequest,
  mapToUpdatePetRequest,
  PetBodyCondition,
  PetFormData,
  PetFormOptions
} from "@/components/PetInformation/PetInformation.vm";
import { ApiInterface } from "@/api/api.interface";

import { Gender, mapPetDetailsResponseToViewModel, PetDetails } from "@/components/common.vm";
import { ConsultationModes } from "@/components/Dashboard/Dashboard.vm";
import BodyConditionScoreSelector from "@/components/PetInformation/BodyConditionScoreSelector.vue";
import moment from "moment";
import { capitalize } from "@/utils/capitalize.function";
import store from "@/store";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import petService from "@/services/pet.service";
import { FeatureFlags } from "@/api/featureFlags/featureFlags.api";
import practiceService from "@/services/practiceService";
import { CustomerType, UpdatePetResponse } from "@/api/api.dto";
import BrandModal from "@/components/BrandModal/BrandModal.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export type ApiErrors = {
  populateBodyConditions: string;
  getAllBreeds: string;
  getPetById: string;
  petType: string;
};

type PracticeDropdown = {
  value: number;
  text: string;
};

type Data = {
  petFormData: PetFormData;
  petFormOptions: PetFormOptions;
  savePetDataResponse: string;
  apiErrors: ApiErrors;
  isViewOrEditActiveMode: boolean;
  requiresUrgentDiet: boolean;
  bodyConditionsScores: any;
  petId?: number;
  practiceDropdownItems: Array<PracticeDropdown>;
  initialPetDetails: PetDetails;
  petTypes: Array<CustomerType>;
  practiceErrorMessage: string;
  showDietReselectWarning: boolean;
  showDietReselectWarningSaveForLater: boolean;
  calculatedLifeStage: string;
  calculatingLifeStage: boolean;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  populateFormOptions(): void;
  populateAllBreeds(): void;
  populateAllBodyConditions(): void;
  savePet(): Promise<void>;
  saveForLater(): void;
  updatePet(): Promise<void>;
  handleSubmit(event: Event): Promise<void>;
  clearAllErrors(): void;
  loadPetData(): void;
  petIdExists(): boolean;
  isEmptyObject(o: object): boolean;
  redirectToUrgentDiet(pet_id: string): void;
  redirectToDietRequirements(pet_id: string): void;
  getDateOfBirth(): string;
  setDateOfBirth(e: any): void;
  getDateOfBirthISO(): string | null;
  isDateOfBirthInFuture(): boolean;
  getSelectedBodyCondition(): PetBodyCondition | undefined;
  loadPractices(): void;
  validatePracticeForm(): boolean;
  isInvalid(field: string): boolean;
  refreshPetTypes(): void;
  loadCurrentLifeStage(): void;
  hasDOBorBreedChanged(): boolean;
  cancelSubmit(): void;
};

type Computed = any;

function getBodyConditionsByGroup(conditions: PetBodyCondition[], groupName: string) {
  return conditions.filter((s: PetBodyCondition) => s.name === groupName);
}

export default Vue.extend<Data, Methods, Computed, any & Inject>({
  name: "PetInformation",
  components: { LoadingSpinner, WithOldVetBar, BodyConditionScoreSelector, BrandModal },
  inject: ["Api"],
  data() {
    return {
      submitDisabled: false,
      activityTypes: {
        leisurely: "leisurely",
        typical: "typical",
        active: "active",
        highlyActive: "highly-active"
      },
      petFormData: {
        activityLevel: undefined,
        bodyConditionScore: undefined,
        dateOfBirth: {
          day: null,
          month: null,
          year: null
        },
        gender: null,
        petName: "",
        petWeight: 0,
        spayedOrNeutered: false,
        customerId: +this.$route.params.customer_id,
        vetPracticeId: null,
        petTypeId: null
      } as PetFormData,
      petFormOptions: {
        breeds: {
          all: [],
          crossbreed: []
        },
        petBodyConditions: []
      } as PetFormOptions,
      initialPetDetails: {} as PetDetails,
      savePetDataResponse: "",
      apiErrors: {
        populateBodyConditions: "",
        getAllBreeds: "",
        getPetById: "",
        petType: ""
      } as ApiErrors,
      isViewOrEditActiveMode:
        store.state.consultationMode === ConsultationModes.VIEW_OR_EDIT_ACTIVE,
      requiresUrgentDiet: false,
      genders: Gender,
      petTypes: [],
      bodyConditionsScores: {},
      practiceDropdownItems: [] as Array<PracticeDropdown>,
      practiceErrorMessage: "",
      showDietReselectWarning: false,
      showDietReselectWarningSaveForLater: false,
      calculatedLifeStage: "",
      calculatingLifeStage: false
    };
  },
  async mounted() {
    await Promise.all([this.populateFormOptions(), this.loadPractices()]);
    if (this.petIdExists()) {
      await this.loadPetData();
      await this.loadCurrentLifeStage();
    }
    // eslint-disable-next-line no-return-assign
    if (this.petFormData.vetPracticeId) {
      await this.refreshPetTypes();
    }
  },
  filters: {
    capitalize: capitalize
  },
  computed: {
    // eslint-disable-next-line func-names
    scoreDescription: function() {
      return this.getSelectedBodyCondition()?.description;
    },
    // eslint-disable-next-line func-names
    messageForVet: function() {
      return this.getSelectedBodyCondition()?.message;
    },
    // eslint-disable-next-line func-names
    showPracticeDropdown: function() {
      return this.practiceDropdownItems.length > 1 || this.practiceErrorMessage;
    },
    // eslint-disable-next-line func-names
    isCurrentLifeStagePuppy: function() {
      return this.calculatedLifeStage === "puppy";
    }
  },
  watch: {
    calculatedLifeStage(newValue) {
      if (
        newValue === "puppy" &&
        this.petFormData.activityLevel === this.activityTypes.highlyActive
      ) {
        this.petFormData.activityLevel = undefined;
      }
    }
  },
  methods: {
    isInvalid(field: string) {
      try {
        return this.apiErrors[field].length > 0;
      } catch (e) {
        return false;
      }
    },
    getSelectedBodyCondition(): PetBodyCondition | undefined {
      return this.petFormOptions.petBodyConditions.find(
        (condition: PetBodyCondition) =>
          condition.identifier === this.petFormData.bodyConditionScore
      );
    },
    getDateOfBirth(): string {
      const birthday = this.petFormData.dateOfBirth;
      if (birthday.day && birthday.month && birthday.year) {
        return moment(`${birthday.year}-${birthday.month}-${birthday.day}`, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    setDateOfBirth(e: any): void {
      const dateObj = moment(e.target.value, "DD/MM/YYYY");
      this.petFormData.dateOfBirth = {
        year: dateObj.year(),
        month: dateObj.month() + 1, // JS counts months from zero
        day: dateObj.date()
      };
    },
    getDateOfBirthISO(): string | null {
      const dob = this.petFormData.dateOfBirth;
      const parsedDate = moment(`${dob.year}-${dob.month}-${dob.day}`, "YYYY-MM-DD");
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DD");
      }
      return null;
    },
    isDateOfBirthInFuture(): boolean {
      const dob = this.petFormData.dateOfBirth;
      return new Date(dob.year, dob.month, dob.day).getTime() > Date.now();
    },
    validatePracticeForm(): boolean {
      if (!this.petFormData.vetPracticeId) {
        this.practiceErrorMessage = "Please select a primary practice";
        this.$refs.primaryPractice.scrollIntoView({ behavior: "smooth" });
        return false;
      }
      return true;
    },
    async populateFormOptions() {
      await this.populateAllBodyConditions();
      await this.populateAllBreeds();
    },
    petIdExists() {
      return !!this.$route.params.pet_id;
    },
    async populateAllBreeds() {
      this.apiErrors.getAllBreeds = "";
      const response = await this.Api.getAllBreeds();
      if (response.success) {
        const breedsViewModel = mapBreedsPayloadToViewModel(response.breeds);
        this.petFormOptions.breeds.all = breedsViewModel;
        this.petFormOptions.breeds.crossbreed = breedsViewModel.filter(breed =>
          breed.name.toLowerCase().includes("crossbreed")
        );
      } else {
        this.apiErrors.getAllBreeds = "Failed to fetch breeds from the server.";
      }
    },
    async populateAllBodyConditions() {
      const response = await this.Api.getAllPetBodyConditions();
      if (response.success) {
        this.petFormOptions.petBodyConditions = mapPetBodyConditionsPayloadToViewModel(
          response.conditions
        );
        this.bodyConditionsScores = {
          tooThin: getBodyConditionsByGroup(this.petFormOptions.petBodyConditions, "Too Thin"),
          ideal: getBodyConditionsByGroup(this.petFormOptions.petBodyConditions, "Ideal"),
          tooHeavy: getBodyConditionsByGroup(this.petFormOptions.petBodyConditions, "Too Heavy")
        };
      } else {
        this.apiErrors.populateBodyConditions = "Failed to fetch body conditions from the server.";
      }
    },
    hasDOBorBreedChanged() {
      if (this.petIdExists() && this.initialPetDetails.dateOfBirth) {
        const daysComparison =
          this.petFormData.dateOfBirth.day !== this.initialPetDetails.dateOfBirth.day;
        const monthComparison =
          this.petFormData.dateOfBirth.month !== this.initialPetDetails.dateOfBirth.month;
        const yearComparison =
          this.petFormData.dateOfBirth.year !== this.initialPetDetails.dateOfBirth.year;

        return (
          daysComparison ||
          monthComparison ||
          yearComparison ||
          this.petFormData.breedId !== this.initialPetDetails.breed.id
        );
      }
      return false;
    },
    async handleSubmit() {
      if (!this.validatePracticeForm()) {
        return;
      }
      this.submitDisabled = true;
      if (this.petIdExists()) {
        if (
          this.hasDOBorBreedChanged() &&
          !this.showDietReselectWarning &&
          !this.isDateOfBirthInFuture()
        ) {
          this.showDietReselectWarning = true;
          return;
        }
        await this.updatePet();
      } else {
        await this.savePet();
      }
    },
    cancelSubmit() {
      this.showDietReselectWarning = false;
      this.showDietReselectWarningSaveForLater = false;
      this.submitDisabled = false;
    },
    async updatePet() {
      const petId = this.$route.params.pet_id;
      this.clearAllErrors();
      let response = {} as UpdatePetResponse;
      response = await this.Api.updatePet(mapToUpdatePetRequest(this.petFormData), petId);

      if (response.success) {
        if (this.requiresUrgentDiet) {
          this.redirectToUrgentDiet(petId);
        } else {
          await this.redirectToDietRequirements(petId);
        }
      } else {
        const responseErrors = [] as Array<string>;
        Object.keys(response.errors).forEach(key => responseErrors.push(response.errors[key]));
        const errors = responseErrors.join(" | ");
        this.savePetDataResponse = errors || "Couldn't save pet details";
        this.submitDisabled = false;
      }
    },
    async savePet() {
      const payload = mapToUpdatePetRequest(this.petFormData);
      try {
        this.clearAllErrors();

        const response = await petService.createNewPet(payload);
        // Navigate to next page
        if (this.requiresUrgentDiet) {
          this.redirectToUrgentDiet(response.pet_id);
        } else {
          await this.redirectToDietRequirements(response.pet_id);
        }
      } catch (e) {
        const responseErrors = [] as Array<string>;
        Object.keys(e.response.data.resp.errors).forEach(key =>
          responseErrors.push(e.response.data.resp.errors[key])
        );
        const errors = responseErrors.join(" | ");
        this.savePetDataResponse = errors || "Couldn't save pet details";
        this.submitDisabled = false;
      }
    },
    async saveForLater() {
      if (!this.validatePracticeForm()) {
        return;
      }
      this.submitDisabled = true;
      try {
        this.clearAllErrors();
        if (this.petFormData.petName) {
          const petId = this.$route.params.pet_id;
          const payload = mapToPartialUpdatePetRequest(this.petFormData);
          if (petId) {
            if (
              this.hasDOBorBreedChanged() &&
              !this.showDietReselectWarningSaveForLater &&
              !this.isDateOfBirthInFuture()
            ) {
              this.showDietReselectWarningSaveForLater = true;
              return;
            }
            await petService.partialUpdatePet(petId, payload);
          } else {
            await petService.partialCreatePet(payload);
          }

          if (this.hasDOBorBreedChanged()) {
            if (this.requiresUrgentDiet) {
              this.redirectToUrgentDiet(petId);
            } else {
              await this.redirectToDietRequirements(petId);
            }
          } else {
            await this.$router.push({
              name: "customer-hub",
              params: this.$route.params.customer_id
            });
          }
        } else {
          this.savePetDataResponse = "Pet name must be entered to save for later";
          this.submitDisabled = false;
        }
      } catch (e) {
        const error = e.response.data.resp.error;
        this.savePetDataResponse = Array.isArray(error) ? error.join(" | ") : error;
        this.submitDisabled = false;
      }
    },
    clearAllErrors() {
      this.savePetDataResponse = "";
      this.apiErrors.getAllBreeds = "";
      this.apiErrors.getPetById = "";
      this.practiceErrorMessage = "";
    },
    async loadPetData() {
      const petId = this.$route.params.pet_id;
      if (petId) {
        this.apiErrors.getPetById = "";
        const petResponse = await this.Api.getPetById(petId);
        if (petResponse.success) {
          this.initialPetDetails = mapPetDetailsResponseToViewModel(petResponse.petDetails);

          this.petFormData.activityLevel = this.initialPetDetails.activityLevel.identifier
            ? this.initialPetDetails.activityLevel.identifier
            : this.petFormData.activityLevel;
          this.petFormData.bodyConditionScore = this.initialPetDetails.condition.identifier
            ? this.initialPetDetails.condition.identifier
            : this.petFormData.bodyConditionScore;
          this.petFormData.breedId = this.initialPetDetails.breed.id;
          this.petFormData.dateOfBirth = this.initialPetDetails.dateOfBirth
            ? this.initialPetDetails.dateOfBirth
            : this.petFormData.dateOfBirth;
          this.petFormData.gender = this.initialPetDetails.gender;
          this.petFormData.petName = this.initialPetDetails.name;
          this.petFormData.petWeight = this.initialPetDetails.weight;
          this.petFormData.spayedOrNeutered = this.initialPetDetails.neutered;
          this.petFormData.customerId = this.initialPetDetails.customerId;
          // if we are dealing with empty pet, select empty value in practice dropdown
          this.petFormData.vetPracticeId =
            !this.initialPetDetails.name && this.practiceDropdownItems.length > 1
              ? null
              : this.initialPetDetails.vetPracticeId;
          this.petFormData.petTypeId = this.initialPetDetails.petTypeId;
        } else {
          this.apiErrors.getPetById = "Could not get pet data.";
        }
      }
    },
    async loadPractices() {
      const practices = await practiceService.getAvailablePractices();
      this.practiceDropdownItems = practices.map(practice => {
        return { value: practice.practiceId, text: practice.name };
      });
      if (this.practiceDropdownItems.length === 1) {
        this.petFormData.vetPracticeId = this.practiceDropdownItems[0].value;
      } else if (this.practiceDropdownItems.length === 0) {
        this.practiceErrorMessage = "Error - no available practices";
      }
    },
    isEmptyObject(o: object): boolean {
      return !Object.values(o).some(x => x !== null && x !== "");
    },
    redirectToUrgentDiet(pet_id: string): void {
      this.$router.push({ name: "urgent-diet", params: { pet_id: pet_id } });
    },
    async redirectToDietRequirements(pet_id: string) {
      const [isFeedingPreferencesEnabled, isBrandQuestionEnabled] = await Promise.all([
        FeatureFlags.isEnabled("v2p:feeding-preferences"),
        FeatureFlags.isEnabled("v2p:brand-question")
      ]);
      const routeName =
        isFeedingPreferencesEnabled || isBrandQuestionEnabled
          ? "feeding-preferences"
          : "diet-requirements";

      await this.$router.push({
        name: routeName,
        params: { pet_id: pet_id }
      });
    },
    async refreshPetTypes() {
      this.practiceErrorMessage = "";
      this.petTypes = await practiceService.getPetTypesAvailableForPractice(
        this.petFormData.vetPracticeId
      );
    },
    async loadCurrentLifeStage() {
      const dob = this.getDateOfBirthISO();
      if (!dob) {
        return;
      }
      const breedId = this.petFormData.breedId;
      if (this.isDateOfBirthInFuture()) {
        // future as a 'puppy' to keep an input disabled
        this.calculatedLifeStage = "puppy";
      } else if (dob && breedId) {
        this.calculatingLifeStage = true;
        try {
          this.calculatedLifeStage = await petService.getCurrentLifeState(dob, breedId);
        } finally {
          this.calculatingLifeStage = false;
        }
      }
    }
  }
});
