<script>
import Vue from "vue";

export default Vue.extend({
  name: "BrandModal",
  props: {
    title: String,
    identifier: String,
    body: String,
    showRetryButton: {
      type: Boolean,
      default: false
    },
    showContinueButton: {
      type: Boolean,
      default: true
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    continueButtonText: {
      type: String,
      default: "Continue"
    },
    additionalBtnCssClasses: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  }
});
</script>
<template>
  <div class="ModalWrapper" :id="identifier">
    <div class="modal" tabindex="-1" role="dialog" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" data-cy="brand-modal-title">{{ title }}</h5>
            <button
              type="button"
              data-cy="brand-modal-close"
              class="close"
              @click="$emit('onCancel')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" data-cy="brand-modal-body">
            <p v-if="body">{{ body }}</p>
            <slot v-else></slot>
          </div>
          <div class="modal-footer">
            <button
              v-if="showContinueButton"
              type="button"
              data-cy="brand-modal-continue"
              @click="$emit('onConfirm')"
              class="btn brand-button"
              :class="additionalBtnCssClasses"
            >
              {{ continueButtonText }}
            </button>
            <button
              v-if="showCancelButton"
              type="button"
              data-cy="brand-modal-cancel"
              class="btn brand-button brand-button-cta"
              :class="additionalBtnCssClasses"
              data-dismiss="modal"
              @click="$emit('onCancel')"
            >
              Cancel
            </button>
            <button
              v-if="showRetryButton"
              type="button"
              data-cy="brand-modal-retry"
              class="btn brand-button "
              :class="additionalBtnCssClasses"
              data-dismiss="modal"
              @click="$emit('onRetry')"
            >
              Retry
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.ModalWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.61);
  z-index: 100;
}
.red-alert {
  position: relative;
  border: 1px solid #c16b63;
  padding: 0.75rem 1.25rem;
  background-color: #f4e6e5;
}
.green-alert {
  position: relative;
  border: 1px solid #9eb89a;
  padding: 0.75rem 1.25rem;
  background-color: #dce5de;
}
.btn-free-width {
  width: inherit;
}
</style>
