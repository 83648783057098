import {
  BreedRequestPayload,
  UpdatePetRequest,
  PetBodyConditionRequestPayload,
  PartialUpdatePetRequest
} from "@/api/api.dto";

export type Breed = {
  id: number;
  name: string;
};

export type PetBodyCondition = {
  identifier: string;
  name: string;
  conditionId: number;
  message: string;
  description: string;
};

export type PetFormOptions = {
  petBodyConditions: PetBodyCondition[];
  breeds: {
    all: Breed[];
    crossbreed: Breed[];
  };
};

export interface PetFormData {
  activityLevel?: string;
  bodyConditionScore: string;
  breedId: number;
  dateOfBirth: {
    day: number | null;
    month: number | null;
    year: number | null;
  };
  gender: string | null;
  petName: string;
  petWeight: number;
  spayedOrNeutered: boolean;
  customerId?: number | null;
  vetPracticeId?: number | null;
  petTypeId?: number | null;
}

export interface CustomerFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dataCaptureConsent?: boolean;
  petHealthClubMember: boolean;
}

export function mapToUpdatePetRequest(petFormData: PetFormData): UpdatePetRequest {
  try {
    return {
      name: petFormData.petName,
      breed: petFormData.breedId,
      date_of_birth: `${petFormData.dateOfBirth.year}-${petFormData.dateOfBirth.month}-${petFormData.dateOfBirth.day}`,
      gender: petFormData.gender,
      is_neutered: petFormData.spayedOrNeutered,
      condition: petFormData.bodyConditionScore,
      weight: petFormData.petWeight,
      activity_level: petFormData.activityLevel,
      customer_id: petFormData.customerId,
      vet_practice_id: petFormData.vetPracticeId,
      pet_type_id: petFormData.petTypeId
    } as UpdatePetRequest;
  } catch (error) {
    return {} as UpdatePetRequest;
  }
}

export function mapToPartialUpdatePetRequest(petFormData: PetFormData): PartialUpdatePetRequest {
  try {
    return {
      name: petFormData.petName,
      breed: petFormData.breedId,
      date_of_birth:
        petFormData.dateOfBirth.year && petFormData.dateOfBirth.month && petFormData.dateOfBirth.day
          ? `${petFormData.dateOfBirth.year}-${petFormData.dateOfBirth.month}-${petFormData.dateOfBirth.day}`
          : null,
      gender: petFormData.gender,
      is_neutered: petFormData.spayedOrNeutered,
      condition: petFormData.bodyConditionScore,
      weight: petFormData.petWeight,
      activity_level: petFormData.activityLevel,
      customer_id: petFormData.customerId,
      vet_practice_id: petFormData.vetPracticeId,
      pet_type_id: petFormData.petTypeId
    } as UpdatePetRequest;
  } catch (error) {
    return {} as UpdatePetRequest;
  }
}

export function mapPetBodyConditionsPayloadToViewModel(
  payload: PetBodyConditionRequestPayload[]
): Array<PetBodyCondition> {
  return payload.map(
    condition =>
      ({
        identifier: condition.identifier,
        description: condition.description,
        name: condition.name,
        message: condition.message,
        conditionId: condition.condition_id
      } as PetBodyCondition)
  ) as Array<PetBodyCondition>;
}

export function mapBreedsPayloadToViewModel(payload: BreedRequestPayload[]): Array<Breed> {
  return payload.map(
    breed =>
      ({
        id: breed.breed_id,
        name: breed.name
      } as Breed)
  ) as Array<Breed>;
}
