<script>
import Vue from "vue";

export default Vue.extend({
  name: "LoadingSpinner",
  data() {
    return {
      isLongRequest: false
    };
  },
  mounted() {
    this.longRequestTimeout();
  },
  methods: {
    longRequestTimeout() {
      setTimeout(() => {
        this.isLongRequest = true;
      }, 5000);
    }
  }
});
</script>

<template>
  <b-container data-cy="loading-spinner">
    <b-row>
      <b-col class="text-center">
        <b-spinner :loading="true" />
        <div>
          <h2>Loading data...</h2>
          <small v-show="isLongRequest">
            This is taking longer than usual, please wait.
          </small>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
